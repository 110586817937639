<template>
  <div class="info-list">
    <ul>
      <li>{{ item.deptName }}</li>
      <li>状态：{{ onlineStatus }}（{{ lastUpdateTime }}前更新）</li>
      <li>最近一次点位上报：{{ item.deviceTime }}</li>
      <li>随身设备电量：{{ item.soc }}%</li>
      <li>当日步行距离：{{ miles }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    lastUpdateTime() {
      return this.item.deviceTime
    },
    onlineStatus() {
      return this.online ? '在线' : '离线'
    },
    miles() {
      return ''
    }
  }
}
</script>
<style lang="less" scoped>
.info-list {
  // margin-left: 110px;
  margin-top: -5px;
  ul {
    margin-left: 0;
    padding-left: 0;
  }
}
.info-list ul li {
  height: 24px;
  line-height: 24px;
}
</style>
